import { graphql } from "gatsby"

export { Home as default } from "@views"

export const pageQuery = graphql`
  query {
    strapiAuthor {
      bio
      avatar {
        width
        height
        url
        formats {
          format
          url
          width
        }
      }
    }
    allStrapiArticle(
      filter: { status: { eq: "published" } }
      sort: { fields: published_at, order: DESC }
    ) {
      edges {
        node {
          title
          slug
          description
          published_at
          banner {
            width
            height
            url
            formats {
              format
              url
              width
            }
          }
        }
      }
    }
  }
`
